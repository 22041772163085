// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/warrenchan/Desktop/Louie/alllcare/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/Users/warrenchan/Desktop/Louie/alllcare/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("/Users/warrenchan/Desktop/Louie/alllcare/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("/Users/warrenchan/Desktop/Louie/alllcare/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/warrenchan/Desktop/Louie/alllcare/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/warrenchan/Desktop/Louie/alllcare/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-services-js": () => import("/Users/warrenchan/Desktop/Louie/alllcare/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-success-js": () => import("/Users/warrenchan/Desktop/Louie/alllcare/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/warrenchan/Desktop/Louie/alllcare/.cache/data.json")

